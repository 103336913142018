import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { page } from "components/page";
import { navigate } from "gatsby";
import qs from "query-string";

const setResourceSearchHistory = gql`
  mutation setResourceSearchHistory($form: SearchHistoryForm) {
    result: setResourceSearchHistory(Input: $form) {
      success
      message
      errorType
    }
  }
`;

@withI18next(["common"])
@page
class ResourceRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {
    this.resourceRedirect(this.props);
  }

  componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      this.resourceRedirect(props);
    }
  }

  @autobind
  resourceRedirect(props) {
    let { search } = props;
    let params = { ...search };
    let {
        mode,
        searchField,
        searchInput,
        op,
        resourceType,
        searchTarget,
        refType,
        refID,
    } = params;
    let redirectParams = {
        mode,
        searchField,
        searchInput,
        op,
        pageType: "search",
        resourceType,
        searchTarget,
    }
    searchField = [].concat(searchField || []);
    searchInput = [].concat(searchInput || []);
    op = [].concat(op || []);
    let sTag = searchTarget || "";
    refType = refType || "";
    refID = refID || "";
    client.jumperrwdClient
      .mutate({
        mutation: setResourceSearchHistory,
        variables: {
          form: {
            mode,
            searchInput,
            searchField,
            op,
            sTag,
            refType,
            refID
          },
        },
      })
      .then((res) => {
        navigate(["/resourceList", qs.stringify(redirectParams)].join("?"));
      });
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default ResourceRedirect;
